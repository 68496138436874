.container {
  margin-top: 40px;
  justify-content: center;
  display: flex;
}

.wrapper {
  flex-basis: 100%;
  max-width: 500px;
}

.wrapper > div {
  border-radius: 4px;
}
