/* stylelint-disable selector-max-specificity */
/* stylelint-disable selector-pseudo-class-no-unknown */
:global(#authentication-frontend) {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: calc(100vh - 110px);
  color: #121212;
  flex: 1 1 auto;
}

@media (min-width: 768px) {
  :global(#authentication-frontend) {
    padding-bottom: 40px;
  }
}
